.navbar {
  padding: 0rem 1rem;
  background-color: #000000;
  border-bottom: 3px solid #7E5B9A;
}

.navbar-brand {
  margin-right: 40px;
}

.navbar-light .navbar-nav .nav-link {
  font-weight: bold;
  font-size: 1.2em;
  color: #400080;
  padding: 5px auto;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #7E5B9A;
}

.dropdown-menu {
  border-radius: 0;
  min-width: 14rem;
}
