.loading_image {
  position: absolute;
  top: 35%;
  left: 40%;
  z-index: 50;
}

.sliding-pane-overlay {
  z-index: 10;
}
