.entityRow td {
  border-left: none;
  border-right: none;
}

.entityRow_comments_editable {
  border: 1px solid transparent;
}
.entityRow_comments:hover > .entityRow_comments_editable {
  border: 1px solid #dddddd;
  background-color: #ffffff;
}
