.activityRow td {
  border-left: none;
  border-right: none;
}

.activityRow_comments_editable {
  border: 1px solid transparent;
}
.activityRow_comments:hover > .activityRow_comments_editable {
  border: 1px solid #dddddd;
  background-color: #ffffff;
}
